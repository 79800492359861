exports.config = {
  sites: [
    {
      name: 'Oasis App',
      repo: 'OasisDEX/oasis-app',
      branch: 'dev',
      contents: [{
        name: "Common",
        type: "json",
        languages: {
          "English": "public/locales/en/common.json",
          "Spanish": "public/locales/es/common.json",
          "Portuguese": "public/locales/pt/common.json",
          "Chinese": "public/locales/zh/common.json",
        }
      }]
    },
    {
      name: 'Oasis Borrow',
      repo: 'OasisDEX/oasis-borrow',
      branch: 'dev',
      contents: [{
        name: "Common",
        type: "json",
        languages: {
          'English': 'public/locales/en/common.json',
          'Spanish': 'public/locales/es/common.json',
          'Portuguese': 'public/locales/pt/common.json',
          'Chinese': 'public/locales/cn/common.json',
        },
        ignoredKeys: 'lang-dropdown' // (it should be the same in each language)
      }]
    },
  ],
  uselessNumber: 1 // (change this for bumping the build)
}

exports.validateConfig = function(config) {
  const allContents = config.sites.map(site => site.contents).flat()
  if (!allContents.every(content => content.languages["English"])) {
    throw new Error('Invalid config: Every "content" must have an English file.')
  }
  if (!allContents.every(content => content.name && content.type)) {
    throw new Error('Invalid config: Every "content" must have a name and a type.')
  }
}
