import React from 'react'
import MainLayout from "../layouts/main-layout"
import { getStatusesCount, getStatsPageUrl, matchesSiteAndContent } from "../util"
import Status from "../components/status"
import { Text, Box, Flex } from "theme-ui"
import { graphql, Link } from "gatsby"

import GithubIconLink from "../components/github-icon-link"
import { config } from '../../config'

const LangLink = ({href, translationMeta, children}) => {
  const statusesWithCount = getStatusesCount(translationMeta).filter(info => info.count) // only those above 0

  return <Link to={href} style={{textDecoration: 'none'}}>
    <Text variant="microHeading" style={{marginBottom: '2px', display: 'inline'}}>{children}</Text>
    <Box>
      {statusesWithCount.map(({status, count}) => <span><Status status={status}/><Text variant="caps" sx={{display: 'inline', fontSize: 1}}>: {count}{" "}</Text></span>)}
    </Box>
  </Link>
}

const Index = ({pageContext: {buildDate}, data}) => {
  const sitesData = JSON.parse(data.sitesData.content)
  return <MainLayout buildDate={buildDate}>
    <Text variant="heading">Overview</Text>
    {config.sites.map(site => <Box>
      <Flex sx={{alignItems: 'center', mt: '40px'}}>
        <Text variant="smallHeading">{site.name}{' '}</Text>
        <Text variant="caps" sx={{ml: '4px'}}>{site.branch}</Text>
        <GithubIconLink repo={site.repo} sx={{ml: '10px'}}/>
      </Flex>
      {site.contents.map(content => <Box>
        <Box sx={{py: 1, borderBottom: '1px solid lightgray'}}>
          <Text sx={{display: 'inline'}}>{content.name}</Text>
          {' '}
          <Link to={getStatsPageUrl(site, content.name, 'English')}>Edit English</Link>
        </Box>
          {sitesData
            .find(data => matchesSiteAndContent(data, {site, content}))
            .messages.translated.map(({ lang, translationMeta }) => <Box sx={{ my: "8px" }}>
              <LangLink href={getStatsPageUrl(site, content.name, lang)} translationMeta={translationMeta}>
                {lang}
              </LangLink>
            </Box>)
          }
        </Box>)
      }
    </Box>)
    }
  </MainLayout>
}

export default Index

export const query = graphql`
  query LandingQuery {
    sitesData: rawFile(name: {eq: "sites-data"}) {
      content
    }
  }  
`
