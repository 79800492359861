import React from 'react'
import { Box } from 'theme-ui'

const GithubIconLink = ({ repo, sx, ...props }) => {
  return (
    <Box sx={{ display: "inline-block", height: '24px',  ...sx }} {...props}>
      <a href={`https://github.com/${repo}`} target="_blank" rel="noopener noreferrer" alt="GitHub">
        <img src="/img/github.png" />
      </a>
    </Box>
  )
}

export default GithubIconLink