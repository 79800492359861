import React from 'react'
import { TRANSLATION_STATUS } from "../constants"
import { Text } from 'theme-ui'

const Status = ({status}) => {
  const colors = {
    [TRANSLATION_STATUS.UP_TO_DATE]: 'green',
    [TRANSLATION_STATUS.MISSING]: 'red',
    [TRANSLATION_STATUS.OUTDATED]: 'orange',
    [TRANSLATION_STATUS.COPIED]: 'teal',
    [TRANSLATION_STATUS.OBSOLETE]: '#645445',
  }
  return <Text variant="caps" sx={{color: colors[status], display: 'inline', fontSize: 1}}>{status}</Text>
}

export default Status